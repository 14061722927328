import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Info } from './Info'
import { Sidebar } from './Sidebar'

export interface Props {
  bookButtonURL?: string
  description?: string
  infoDescription?: string
  infoTitle?: string
  languageCode: string
  requestButtonURL?: string
  sidebarSubtitle?: string
  sidebarTitle?: string
  subtitle?: string
  title?: string
}

export const EventContent = memo(function EventContent({
  bookButtonURL,
  description,
  infoDescription,
  infoTitle,
  languageCode,
  requestButtonURL,
  sidebarSubtitle,
  sidebarTitle,
  subtitle,
  title,
}: Props) {
  return (
    <Container row tag="section" wrap>
      <LeftSide>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {subtitle ? (
          <FadeInUp>
            <Subtitle>{subtitle}</Subtitle>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        <Info description={infoDescription} title={infoTitle} />
      </LeftSide>
      <RightSide>
        <Sidebar
          bookButtonURL={bookButtonURL}
          languageCode={languageCode}
          requestButtonURL={requestButtonURL}
          subtitle={sidebarSubtitle}
          title={sidebarTitle}
        />
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-bottom: 5.875rem;
  padding: 0 5.833vw;

  @media (max-width: 1023px) {
    margin-bottom: 5rem;
  }
`

const LeftSide = styled.div`
  width: 60%;
  padding: 3.75rem 9.444vw 0 0;

  @media (max-width: 1023px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 4rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 3.125rem;
    line-height: 2.875rem;
  }
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  margin-top: 0.375rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-top: 0.75rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 2.5rem;

  p {
    margin: inherit;
  }

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
  }
`

const RightSide = styled.div`
  width: 40%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  border: 0.625rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 4.8125rem 7.5vw;
  position: relative;
  transform: translateY(-3.75rem);
  z-index: 3;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 3.75rem;
    padding: 3rem 6.039vw;
    transform: none;
  }
`
