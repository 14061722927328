import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Check } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  bookButtonURL?: string
  languageCode: string
  requestButtonURL?: string
  subtitle?: string
  title?: string
}

export const Sidebar = memo(function Sidebar({
  bookButtonURL,
  languageCode,
  requestButtonURL,
  subtitle,
  title,
}: Props) {
  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {subtitle ? (
        <FadeInUp>
          <Subtitle>{subtitle}</Subtitle>
        </FadeInUp>
      ) : null}
      {bookButtonURL ? (
        <FadeInUp>
          <BookButton
            label={useVocabularyData('book-now', languageCode)}
            target="_blank"
            URL={bookButtonURL}
          />
        </FadeInUp>
      ) : null}
      {requestButtonURL ? (
        <FadeInUp>
          <RequestButton
            label={useVocabularyData('request-info', languageCode)}
            URL={requestButtonURL}
            variant="S"
          />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  text-transform: uppercase;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.875rem;
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 2rem;
  margin-top: 0.75rem;
`

const BookButton = styled(Button)`
  width: 100%;
  margin-top: 2.5rem;
`

const RequestButton = styled(Button)`
  width: 100%;
  max-width: none;
  margin-top: 0.75rem;
`
